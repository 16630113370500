import React from "react";
import { useTranslation } from 'react-i18next';
import {
  Segment,
  // Header,
  Grid,
  List,
  Label,
  Icon,
  Button,
} from "semantic-ui-react";

const FooterComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Segment
        attached
        basic
        style={{ backgroundColor: "#1a1b1c", margin: "0 0 0 0" }}
      >
        <Grid
          stackable
          container
          centered
          style={{ marginTop: "5vh" }}
          verticalAlign={"middle"}
        >
          <Grid.Row centered>
            <Grid.Column width={5}>
              <Label>
                <Icon name="clock outline" /> {t('footer-info.1')}
              </Label>
              <List style={{ color: "#fff" }}>
                <List.Content>
                  <List.Header>{t('footer-info.2')}</List.Header>
                  <List.Description>08:30 - 15:00 </List.Description>
                  <List.Header>{t('footer-info.3')}</List.Header>
                  <List.Description>
                    08:30 - 14:30 & 17:30 - 21:00
                  </List.Description>
                </List.Content>
              </List>
            </Grid.Column>
            <Grid.Column width={5}>
              <Label>
                <Icon name="address book" />
                {t('footer-info.4')}
              </Label>
              <List style={{ color: "#fff" }}>
                <List.Item>
                  <Icon name="mail" />
                  info@kampourisdeli.gr
                </List.Item>
                <List.Item>
                  <Icon name="phone" />
                  +30 2321 023236
                </List.Item>

                <List.Item>
                  <Icon name="fax" />
                  +30 2321 098370
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={5}>
              <Label>
                <Icon name="map marker alternate" />
                {t('footer-info.5')}
              </Label>
              <List style={{ color: "#fff" }}>
                <List.Item>
                {t('footer-info.6')} <br /> 62122 Sérres, Greece
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered style={{ marginTop: "5vh" }}>
            <Button
              circular
              color="facebook"
              as={"a"}
              href="https://www.facebook.com/kampourisKreopoleio/"
              icon={"facebook f"}
              target="_blank"
              aria-label="facebook"
            />
            <Button
              as={"a"}
              href="https://www.instagram.com/kreopoleiokampouris/"
              circular
              color="purple"
              target="_blank"
              icon={"instagram"}
              aria-label="instagram"
            />
          </Grid.Row>
          <Grid.Row style={{ marginTop: "3vh", marginBottom: "1vh" }}>
            <Segment basic style={{ color: "#fff" }}>
              Copyright © {new Date().getFullYear()} Kampouris LTD. All rights
              reserved.
            </Segment>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default FooterComponent;
