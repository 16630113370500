import React from "react";
import { Grid, Header, Image, Responsive, Segment } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

export const MenuComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Responsive minWidth={1200}>
        {/* desktop */}
        <Segment basic style={{ width: "100vw", margin: 0, padding: 0 }}>
          {/* 1 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.1')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.1')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xoirkare.jpg"}></Image>
            </Grid.Column>
          </Grid>
          {/* 2 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/karameles.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.2')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.2')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>
          {/* 3 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.3')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.3')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/rozmous.jpg"}></Image>
            </Grid.Column>
          </Grid>
          {/* 4 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xlaimos.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.4')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.4')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>
          {/* 5 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.5')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.5')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xpsaronefri.jpg"}></Image>
            </Grid.Column>
          </Grid>

          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image
                src={process.env.PUBLIC_URL + "/xpsaronefrigrav.jpg"}
              ></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.6')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.6')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 6 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.7')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.7')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image
                src={process.env.PUBLIC_URL + "/xmprizolakastano.jpg"}
              ></Image>
            </Grid.Column>
          </Grid>

          {/* 7 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xkotsia.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.8')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.8')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 8 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.9')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.9')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xporketa1.jpg"}></Image>
            </Grid.Column>
          </Grid>

          {/* 9 
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xporketa2.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                  Χοιρινή πορκέτα με αρωματικά, ελαιόλαδο και ροζ πιπέρι
                </Header>

                <Header style={{ margin: "5vw" }}>
                  Tip: Ψήστε στη γάστρα ή σκεπάστε με λαδόκολλα σε ένα ταψί,
                  προσθέτοντας λίγο ελαιόλαδο και ένα φλιτζάνι του καφέ, νερό ή
                  λευκό κρασί.
                </Header>
              </Segment>
            </Grid.Column>
          </Grid> */}

          {/* 10 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xpesto.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.10')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.10')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 11 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.11')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.11')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xpraso.jpg"}></Image>
            </Grid.Column>
          </Grid>

          {/* 12 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xkaram.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.12')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.12')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 13 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.13')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.13')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xspalafas.jpg"}></Image>
            </Grid.Column>
          </Grid>

          {/* 14 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/xprasosel.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.14')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.14')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 15 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.15')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.15')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/mkoniak.jpg"}></Image>
            </Grid.Column>
          </Grid>

          {/* 16 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/mfrouta.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.16')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.16')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 17 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.17')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.17')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/mrodi.jpg"}></Image>
            </Grid.Column>
          </Grid>

          {/* 18 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/cmpouti.jpg"}></Image>
            </Grid.Column>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.18')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.18')}
                </Header>
              </Segment>
            </Grid.Column>
          </Grid>

          {/* 19 */}
          <Grid style={{ borderBottom: "15px solid brown" }}>
            <Grid.Column width={5} style={{ padding: 0, margin: 0 }}>
              <Segment raised inverted style={{ height: "100%" }}>
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.19')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.19')}
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column width={11} style={{ padding: 0, margin: 0 }}>
              <Image src={process.env.PUBLIC_URL + "/cstithos.jpg"}></Image>
            </Grid.Column>
          </Grid>
        </Segment>
      </Responsive>

      {/* mobile/tableut */}
      <Responsive maxWidth={1200}>
        <Segment
          basic
          style={{ width: "100vw", margin: 0, padding: 0, height: "100%" }}
        >
          {/* 1 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.1')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.1')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xoirkare.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 2 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.2')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.2')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/karameles.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 3 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.3')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.3')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/rozmous.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 4 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.4')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.4')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xlaimos.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 5 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.5')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.5')}
                </Header>
                <Image
                  src={process.env.PUBLIC_URL + "/xpsaronefri.jpg"}
                ></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 6 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.7')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.7')}
                </Header>
                <Image
                  src={process.env.PUBLIC_URL + "/xmprizolakastano.jpg"}
                ></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 7 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.8')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.8')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xkotsia.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 8 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.9')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.9')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xporketa1.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 9 
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                  Χοιρινή πορκέτα με αρωματικά, ελαιόλαδο και ροζ πιπέρι
                </Header>

                <Header style={{ margin: "5vw" }}>
                  Tip: Ψήστε στη γάστρα ή σκεπάστε με λαδόκολλα σε ένα ταψί,
                  προσθέτοντας λίγο ελαιόλαδο και ένα φλιτζάνι του καφέ, νερό ή
                  λευκό κρασί.
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xporketa2.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>*/}

          {/* 10 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.10')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.10')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xpesto.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 11 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.11')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.11')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xpraso.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 12 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.12')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.12')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xkaram.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 13 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.13')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.13')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xspalafas.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 14 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.14')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.14')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/xprasosel.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 15 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.15')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.15')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/mkoniak.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 16 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "30% 5vw 5vw 5vw" }}>
                {t('recipe.16')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.16')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/mfrouta.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 17 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.17')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.17')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/mrodi.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 18 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.18')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.18')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/cmpouti.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>

          {/* 19 */}
          <Grid>
            <Grid.Row style={{ margin: 0, padding: 0 }}>
              <Segment
                raised
                inverted
                style={{ width: "100%", padding: 0, margin: 0 }}
              >
                <Header as={"h1"} style={{ margin: "10% 5vw 5vw 5vw" }}>
                {t('recipe.19')}
                </Header>

                <Header style={{ margin: "5vw" }}>
                {t('tips.19')}
                </Header>
                <Image src={process.env.PUBLIC_URL + "/cstithos.jpg"}></Image>
              </Segment>
            </Grid.Row>
          </Grid>
        </Segment>
      </Responsive>
    </>
  );
};
