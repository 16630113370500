import menuBanner from "assets/menuvectors.svg";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Grid,
  //Header,
  Image,
  Segment,
  Transition,
  Visibility,
  Responsive,
  Divider,
} from "semantic-ui-react";
//import _ from "lodash";

const LegacyComponent = () => {
  const [visible, setVisible] = useState(false);
  /*const debouncedstate = (args) => {
    setVisible(args);
  };*/
  const { t } = useTranslation();

  const founded = 1930;
  const date = new Date().getFullYear() - founded;

  return (
    <>
      <Segment
        basic
        style={{
          width: "100vw",
          margin: "0 0 0 0",
          borderBottom: "2vh solid #2e2014",
          padding: "0px",
        }}
      ></Segment>
      <Responsive minWidth={750}>
        <Grid style={{ zIndex: "100", backgroundColor: "rgb(238,234,229)" }}>
          <Grid.Column width={10}>
            <Segment
              basic
              style={{
                height: "100vh",
                margin: 0,
                padding: 0,
              }}
            >
              <Grid>
                <Grid.Column width={8}>
                  <Transition
                    animation={"fade down"}
                    visible={visible}
                    duration={1500}
                  >
                    <Image
                      style={{
                        width: "80%",
                        height: "5%",
                        padding: 0,
                        margin: "55% 0 -1% 22%",
                        fontSize: "3vh",
                        fontWeight: "500",
                        fontFamily: "Skia, verveine",
                        color: "#8c7861",
                      }}
                    >
                      {founded} - {new Date().getFullYear()}
                    </Image>
                  </Transition>
                  <Transition
                    animation={"fade down"}
                    visible={visible}
                    duration={1000}
                  >
                    <Image
                      src={require(`assets/SVG/${t('legacy.1')}`)}
                      style={{
                        width: "80%",
                        height: "30%",
                        padding: 0,
                        margin: "0 0 0 20%",
                      }}
                    />
                  </Transition>
                  <Transition
                    animation={"fade up"}
                    visible={visible}
                    duration={1000}
                  >
                    <Image
                      src={require(`assets/SVG/${t('legacy.2')}`)}
                      style={{ width: "55%", margin: "0 0 0 20%" }}
                    />
                  </Transition>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Transition
                    animation={"horizontal flip"}
                    visible={visible}
                    duration={1000}
                  >
                    <Image
                      circular
                      style={{
                        margin: "55% 0 0 10%",
                        backgroundColor: "#ae8f72",
                        width: "15vw",
                        height: "15vw",
                        color: "white",
                        // backgroundColor: '#a99076',
                        backgroundImage: `url("https://www.transparenttextures.com/patterns/black-paper.png")`,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          margin: "50% 0 0 18%",
                          fontSize: "8vw",
                          fontFamily: "Skia, six-hands-marker",
                          fontWeight: 600,
                          fontStyle: "normal",
                        }}
                      >
                        {date.toString().charAt(0)}
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          margin: "42% 0 0 50%",
                          fontSize: "8vw",
                          fontFamily: "Skia, six-hands-marker",
                          fontWeight: 600,
                        }}
                      >
                        {date.toString().charAt(1)}
                      </div>
                    </Image>
                  </Transition>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment
              basic
              style={{
                height: "100vh",
                borderLeft: "1em dotted #b08f71",
              }}
            >
              <Visibility
                updateOn={"events"}
                continuous
                onTopVisible={() => {
                  setVisible(true);
                }}
                onOffScreen={() => {
                  setVisible(false);
                }}
              >
                <Transition
                  animation={"fade left"}
                  visible={visible}
                  duration={900}
                >
                  <Image
                    src={menuBanner}
                    style={{
                      width: "65%",
                      height: "50%",
                      margin: "25% 0 0 10%",
                    }}
                  />
                </Transition>
              </Visibility>
            </Segment>
          </Grid.Column>
        </Grid>
      </Responsive>
      <Responsive maxWidth={750}>
        <Grid style={{ marginTop: "5%" }} centered>
          <Image
            style={{
              width: "80%",
              height: "15%",
              padding: 0,
              margin: "5% 0 0 0",
              fontSize: "5vh",
              fontWeight: "500",
              fontFamily: "Skia, verveine",
              color: "#8c7861",
            }}
          >
            {founded} - {new Date().getFullYear()}
          </Image>
          <Image
            circular
            style={{
              margin: "5% 0 0 0%",
              backgroundColor: "#ae8f72",
              width: "25vw",
              height: "25vw",
              color: "white",
              // backgroundColor: '#a99076',
              backgroundImage: `url("https://www.transparenttextures.com/patterns/black-paper.png")`,
            }}
          >
            <div
              style={{
                position: "absolute",
                margin: "35% 0 0 5%",
                fontSize: "15vw",
                fontFamily: "Skia, six-hands-marker",
                fontWeight: 600,
                fontStyle: "normal",
              }}
            >
              {date.toString().charAt(0)}
            </div>

            <div
              style={{
                position: "absolute",
                margin: "35% 0 0 38%",
                fontSize: "15vw",
                fontFamily: "Skia, six-hands-marker",
                fontWeight: 600,
              }}
            >
              {date.toString().charAt(1)}
            </div>
          </Image>

          <Image
            src={require(`assets/SVG/${t('legacy.1')}`)}
            style={{
              width: "55%",
              height: "30%",
              padding: 0,
              margin: "10% 0 0 5%",
            }}
          />

          <Image
            src={require(`assets/SVG/${t('legacy.2')}`)}
            style={{ width: "55%", margin: "2% 0 0 20%" }}
          />
        </Grid>
        <Divider hidden style={{ marginTop: "10%" }}></Divider>
      </Responsive>
    </>
  );
};

export default LegacyComponent;
