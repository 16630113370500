import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import './i18n';

const httpLink =
  'https://europe-west2-kampouris-react-apollo.cloudfunctions.net/app/gql';

const client = new ApolloClient({
  link: new HttpLink({ uri: httpLink }),
  cache: new InMemoryCache({ dataIdFromObject: object => object.id || null })
});

ReactDOM.render(
  <Suspense fallback={(<div>loading...</div>)}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
