import React from "react";
import HeaderComponent from "components/landing/HeaderComponent";
import LegacyComponent from "components/landing/LegacyComponent";
import RecipesComponent from "components/landing/RecipesComponent";
import { SeasonalComponent } from "components/landing/SeasonalComponent";
import ChristmasTable from "./ChristmasTable";
const Home = () => {
  return (
    <>
      <HeaderComponent></HeaderComponent>
      <SeasonalComponent></SeasonalComponent>
      <ChristmasTable></ChristmasTable>
      <LegacyComponent></LegacyComponent>
      <RecipesComponent></RecipesComponent>
    </>
  );
};

export default Home;
