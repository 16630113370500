import gql from 'graphql-tag';

export const GET_RECIPES = gql`
  query RecipesQuery {
    recipes {
      title
      cooking_time
      description
      id
      category
      temperature
      tip
      image
    }
  }
`;
