import React from "react";
//import easterad from 'assets/SVG/easterad.svg';
//import shoplist from 'assets/SVG/shoplist.svg';
//import eastertext from 'assets/SVG/eastertext.svg';
//import orderstext from 'assets/SVG/orderstext.svg';
//import easterdownloadbutton from 'assets/SVG/easterdownloadbutton.svg';
import { Image, Segment, Grid, Divider, Responsive } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export const SeasonalComponent = () => {

  
  const { t } = useTranslation();
  
  return (
    <>
      <Segment
        basic
        style={{
          width: "100vw",
          backgroundColor: "rgb(238,234,229)",
          margin: "0 0 0 0",
          borderBottom: "2vh solid #2e2014",
        }}
      >
        <Grid centered style={{ marginTop: "2vh" }}>
          <Responsive minWidth={750}>
            <Grid.Row centered>
              <Segment
                basic
                style={{
                  width: "80vw",
                  borderBottom: "1em dotted rgba(170,144,118)",
                }}
              ></Segment>
            </Grid.Row>
          </Responsive>
          <Responsive maxWidth={750}>
            <Grid.Row centered>
              <Segment
                basic
                style={{
                  width: "90vw",
                  borderBottom: "1vw dotted rgba(170,144,118)",
                }}
              ></Segment>
            </Grid.Row>
          </Responsive>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Image size="huge" src={require(`assets/SVG/${t('seasonal.1')}`)} alt="seasonal"></Image>
            </Grid.Column>
            <Grid.Column width={4}>
              <Responsive minWidth={750}>
                {/* <Image
                  size="small"
                  style={{
                    position: 'absolute',
                    zIndex: 1000,
                    top: '29%',
                    left: '45%',
                  }}
                  as={Link}
                  to={'/downloadable/ShoppingList.pdf'}
                  target="_blank"
                  src={kampourisShoppingButton}
                ></Image> */}
              </Responsive>
              {/* <Responsive maxWidth={750}>
                <Image
                  style={{
                    position: 'absolute',
                    zIndex: 1000,
                    width: '12vw',
                    top: '29%',
                    left: '50%',
                  }}
                  as={Link}
                  to={'/downloadable/ShoppingList.pdf'}
                  target="_blank"
                  src={easterdownloadbutton}
                ></Image>
              </Responsive> */}
              <Image
                size="large"
                as={Link}
                to={"/downloadable/ShoppingList.pdf"}
                target="_blank"
                src={require(`assets/SVG/${t('seasonal.3')}`)}
                alt="shopping list"
              ></Image>
            </Grid.Column>
          </Grid.Row>
          <Responsive minWidth={750}>
            <Grid.Row centered>
              <Segment
                basic
                style={{
                  width: "80vw",
                  borderBottom: "1em dotted rgba(170,144,118)",
                }}
              ></Segment>
            </Grid.Row>
          </Responsive>
          <Responsive maxWidth={750}>
            <Grid.Row centered>
              <Segment
                basic
                style={{
                  width: "90vw",
                  borderBottom: "1vw dotted rgba(170,144,118)",
                }}
              ></Segment>
            </Grid.Row>
          </Responsive>
        </Grid>
        <Grid style={{ marginTop: "3%" }}>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width="14">
              <Image size="massive" src={require(`assets/SVG/${t('seasonal.2')}`)} alt="order options"></Image>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden style={{ marginTop: "2vh" }}></Divider>
      </Segment>
    </>
  );
};
