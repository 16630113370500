import React, { useEffect, useState } from "react";
import {
  Grid,
  Icon,
  Image,
  Segment,
  Label,
  Header,
  Responsive,
} from "semantic-ui-react";
// import { IMAGE_API } from 'context';
// import axios from 'axios';
import pork from "assets/pig.svg";
import cow from "assets/beef.svg";
//import chicken from 'assets/chicken.svg';
import turkey from "assets/turkey.svg";

const RecipeCell = ({ data }) => {
  const [img, setImg] = useState();

  useEffect(() => {
    import(`assets/images/${data.image}`)
      .then((image) => {
        // console.log(data.image);
        setImg(image.default);
      })
      .catch((err) => setImg(null));
  }, [data]);

  useEffect(() => {
    console.log(img);
  }, [img]);

  return (
    <>
      <Responsive style={{ marginBottom: "20vh" }} minWidth={750}>
        <Segment.Group raised style={{ backgroundColor: "transparent" }}>
          <Segment style={{ backgroundColor: "transparent" }}>
            <Grid>
              <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
                <Image rounded floated="left" src={img}></Image>
              </Grid.Column>
            </Grid>
            <Label
              color="brown"
              ribbon
              size={"large"}
              style={{
                //   // transform: 'rotate(-3deg)',
                //   top: '94%',
                zIndex: 1000,
                position: "absolute",
                // left: '-0.9vw'
              }}
            >
              <strong>{data.title}</strong>
            </Label>
          </Segment>
          <Segment.Group raised horizontal style={{ backgroundColor: "#fff" }}>
            <Segment
              style={{ width: "33.33%" }}
              padded="very"
              textAlign="center"
            >
              <Header
                as="h4"
                size="medium"
                icon
                textAlign="center"
                style={{ marginTop: "1vh" }}
              >
                <Icon name="thermometer" color="red"></Icon>
                <Header.Content> {data.temperature} °C</Header.Content>
              </Header>
            </Segment>
            <Segment
              style={{ width: "33.33%" }}
              padded="very"
              textAlign="center"
            >
              <Header
                as="h4"
                icon
                size="medium"
                style={{ marginTop: "1vh" }}
                textAlign="center"
              >
                <Icon name="clock outline" color="orange"></Icon>
                <Header.Content> {data.cooking_time} </Header.Content>
              </Header>
            </Segment>

            <Segment
              padded="very"
              textAlign="center"
              style={{ width: "33.33%", maxHeight: "10vw" }}
            >
              {data.category === "pork" && (
                <Image src={pork} style={{ marginTop: "1vh" }}></Image>
              )}
              {data.category === "beef" && (
                <Image src={cow} style={{ marginTop: "1vh" }}></Image>
              )}
              {data.category === "turkey" && (
                <Image
                  src={turkey}
                  style={{ maxHeight: "3vw", marginTop: "1vh" }}
                ></Image>
              )}
            </Segment>
          </Segment.Group>
          <Segment.Group raised style={{ backgroundColor: "#fff" }} horizontal>
            <Segment textAlign="left" padded style={{ width: "50%" }}>
              <strong>{data.description}</strong>
            </Segment>
            <Segment textAlign="right" padded="very" style={{ width: "50%" }}>
              <Label
                attached="top left"
                size="medium"
                color="yellow"
                style={{ transform: "rotate(-15deg)" }}
              >
                <Icon name="lightbulb outline"></Icon>TIP
              </Label>{" "}
              <i>{data.tip}</i>
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Responsive>

      <Responsive maxWidth={750}>
        <Segment.Group raised style={{ backgroundColor: "transparent" }}>
          <Segment style={{ backgroundColor: "transparent" }}>
            <Grid>
              <Grid.Column width={16} style={{ padding: 0, margin: 0 }}>
                <Image rounded src={img}></Image>
              </Grid.Column>
            </Grid>
            <Label
              color="brown"
              ribbon
              size={"tiny"}
              style={{
                //   // transform: 'rotate(-3deg)',
                //   top: '94%',
                zIndex: 1000,
                position: "absolute",
                // left: '-0.9vw'
              }}
            >
              <strong>{data.title}</strong>
            </Label>
          </Segment>

          <Segment.Group raised horizontal style={{ backgroundColor: "#fff" }}>
            <Segment style={{ width: "33%" }} textAlign="center">
              <Header
                as="h4"
                size="small"
                icon
                textAlign="center"
                style={{ marginTop: "1vh" }}
              >
                <Icon name="thermometer" size="small" color="red"></Icon>
                <Header.Content> {data.temperature} °C</Header.Content>
              </Header>
            </Segment>

            <Segment style={{ width: "33%" }} textAlign="center">
              <Header
                as="h4"
                icon
                size="small"
                style={{ marginTop: "1vh" }}
                textAlign="center"
              >
                <Icon size="small" name="clock outline" color="orange"></Icon>
                <Header.Content> {data.cooking_time} </Header.Content>
              </Header>
            </Segment>

            <Segment textAlign="center" style={{ width: "33%" }}>
              {data.category === "pork" && (
                <Image
                  size="tiny"
                  style={{ marginTop: "35%" }}
                  src={pork}
                ></Image>
              )}
              {data.category === "beef" && (
                <Image
                  size="tiny"
                  style={{ marginTop: "35%" }}
                  src={cow}
                ></Image>
              )}
              {data.category === "turkey" && (
                <Image
                  size="tiny"
                  style={{ marginTop: "35%" }}
                  src={turkey}
                ></Image>
              )}
            </Segment>
          </Segment.Group>
          <Segment.Group raised style={{ backgroundColor: "#fff" }} horizontal>
            <Segment textAlign="left" padded style={{ width: "50%" }}>
              <strong> {data.description} </strong>
            </Segment>
            <Segment textAlign="right" padded="very" style={{ width: "50%" }}>
              <Label
                attached="top left"
                size="medium"
                color="yellow"
                style={{ transform: "rotate(-15deg)" }}
              >
                <Icon name="lightbulb outline"></Icon>TIP
              </Label>
              <i>{data.tip}</i>
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Responsive>
    </>
  );
};

export default RecipeCell;
