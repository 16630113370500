import React from "react";
//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SidebarComponent from "components/sidebar/SidebarComponent";
import Home from "components/landing";
import Recipes from "components/recipes/Recipes";
import NoMatch from "components/NoMatch";
import { MenuComponent } from "components/menu/MenuComponent";
import { ChristmasMenuComponent } from "components/menu/ChristmasMenuComponent";

function App() {
  return (
    <Router>
      <SidebarComponent>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/home" exact component={Home}></Route>
          <Route path="/recipes" exact component={Recipes}></Route>
          <Route path="/menu" exact component={MenuComponent}></Route>
          <Route
            path="/christmasmenu"
            exact
            component={ChristmasMenuComponent}
          ></Route>
          <Route exact component={NoMatch}></Route>
        </Switch>
      </SidebarComponent>
    </Router>
  );
}

export default App;
