import { useContext, createContext } from "react";
import { __RouterContext } from "react-router";

export function useRouter() {
  return useContext(__RouterContext);
}

export const SearchContext = createContext(null);

export const IMAGE_API = "http://localhost:4000/image";
