import { useQuery } from '@apollo/client';
import img from 'assets/SVG/athanasia.svg';
import RecipeTable from 'components/recipes/RecipeTable';
import { GET_RECIPES } from 'queries';
import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

const Recipes = () => {
  const { loading, error, data } = useQuery(GET_RECIPES);

  if (loading) return 'Loading..';
  if (error) return 'Something went wrong';

  // const [search, setSearch] = useState('');

  return (
    <>
      <Segment
        basic
        style={{
          background: `url(${img}),linear-gradient(to bottom, #000000 25vh, #292929 66%, #2d2d2d 85%, #461b0b) `,
          backgroundPosition: '100% 15vh, left top',
          backgroundSize: `30vw 100vh, 100%`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed, fixed',
          margin: 0,
        }}
      >
        <Grid columns={2} padded>
          <Grid.Column largeScreen={12} widescreen={13}>
            <RecipeTable data={data.recipes} />
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
};

export default Recipes;
