import React, { useMemo, useContext } from "react";
import {
  useTable,
  //useFilters,
  // useSortBy,
  // useExpanded,
  //usePagination,
} from "react-table";
import {
  Segment,
  Grid,
  // Rail,
  // Sidebar,
  // Menu,
  // Image,
  Divider,
  //Input,
  Responsive,
} from "semantic-ui-react";
import RecipeCell from "components/recipes/RecipeCell";
import matchsorter from "match-sorter";
import { SearchContext } from "context";
const RecipeTable = ({ data }) => {
  const context = useContext(SearchContext);
  console.log(context);
  /*const tableState = {
    pageIndex: 0,
    pageSize: 4,
  };*/
  const { search } = useContext(SearchContext);
  data = useMemo(() => {
    if (search) {
      return matchsorter(data, search, { keys: Object.keys(data[0]) });
    }
    return data;
  }, [search, data]);

  const columns = useMemo(
    () => [
      {
        Header: () => "Title",
        id: "recipes",
        accessor: (r) => r.title,
        Cell: (obj) => <RecipeCell data={obj.row.original} />,
        // filter: filterHandler
      },
    ],
    []
  );

  const {
    getTableProps,
    //getTableBodyProps,
    //headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      <Segment basic>
        <Divider hidden style={{ margin: "10vh 0 5vh 0" }}></Divider>
        <Responsive minWidth={750}>
          <Grid
            stackable
            {...getTableProps()}
            centered
            stretched
            relaxed="very"
            verticalAlign="middle"
          >
            {/* <Grid.Row stretched {...getTableBodyProps()}> */}
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  // <Grid.Column
                  //   widescreen={6}
                  //   largeScreen={9}
                  //   {...row.getRowProps()}
                  // >
                  <>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <Grid.Column
                            largeScreen={6}
                            widescreen={9}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </Grid.Column>
                        </>
                      );
                    })}
                  </>
                  // </Grid.Column>
                )
            )}
          </Grid>
        </Responsive>
        <Responsive maxWidth={750}>
          <Grid {...getTableProps()}>
            {/* <Grid.Row stretched {...getTableBodyProps()}> */}
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  // <Grid.Column
                  //   widescreen={6}
                  //   largeScreen={9}
                  //   {...row.getRowProps()}
                  // >
                  <>
                    {row.cells.map((cell) => {
                      return (
                        <>
                          {/* <Grid.Column width={16} {...cell.getCellProps()}> */}
                          {cell.render("Cell")}
                          <Divider
                            hidden
                            style={{ marginTop: "2vh" }}
                          ></Divider>
                          {/* </Grid.Column> */}
                        </>
                      );
                    })}
                  </>
                  // </Grid.Column>
                )
            )}
          </Grid>
        </Responsive>
      </Segment>
    </>
  );
};

export default RecipeTable;
