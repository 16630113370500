import React from "react";
import { Grid, Header, Image, Label, Segment } from "semantic-ui-react";

import { Link } from "react-router-dom";
import banner from "assets/banner.svg";
const NoMatch = () => {
  return (
    <>
      <Grid centered style={{ height: "100vh" }}>
        <Segment
          inverted
          style={{
            width: "25vw",
            height: "25vw",
            position: "absolute",
            zIndex: 10,
            top: "12vh",
            left: "10vw",
            color: "#a9845f",
          }}
          circular
        >
          <Image style={{ marginTop: "40%" }} src={banner} />
          <Label
            size={"large"}
            color="brown"
            ribbon="right"
            style={{ marginTop: "10vh", marginLeft: "-22vw" }}
          >
            <Link to="/">Κρέατα ποιότητας...</Link>
          </Label>
        </Segment>

        <Segment
          basic
          style={{ position: "absolute", top: "35vh", left: "45vw" }}
        >
          <Label
            /*as={Link}*/ to={"/"}
            size="massive"
            color={"brown"}
            as={Header}
          >
            <Header style={{ color: "#fff", opacity: 1 }}>
              Η σελίδα που αναζητήσατε δεν βρίσκεται στο μενού μας! <br />
              Πατήστε εδώ για να συνεχίσετε την περιήγηση.
            </Header>
          </Label>
        </Segment>
      </Grid>
    </>
  );
};

export default NoMatch;
