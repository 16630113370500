import athanasia from "assets/athanasia1.jpg";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Grid,
  //Header,
  Image,
  Segment,
  Transition,
  Visibility,
  Responsive,
} from "semantic-ui-react";
//import _ from "lodash";
import { Link } from "react-router-dom";

const ChristmasTable = () => {
  const [visible, setVisible] = useState(false);
  /*const debouncedstate = (args) => {
    setVisible(args);
  };*/
  const { t } = useTranslation();


  return (
    <>
      <Responsive minWidth={750}>
        <Grid style={{ zIndex: "100", backgroundColor: "rgb(35,31,32)" }}>
          <Grid.Column width={6}>
            <Segment
              basic
              style={{
                height: "102vh",
                borderRight: "1em dotted #b08f71",
              }}
            >
              <Visibility
                updateOn={"events"}
                continuous
                onTopVisible={() => {
                  setVisible(true);
                }}
                onOffScreen={() => {
                  setVisible(false);
                }}
              >
                <Transition
                  animation={"fade left"}
                  visible={visible}
                  duration={900}
                >
                  <Image
                    src={athanasia}
                    style={{
                      border: "10px solid #b08f71 ",
                      width: "65%",
                      height: "50%",
                      margin: "30% 0 0 25%",
                    }}
                    alt="athanasia"
                  />
                </Transition>
              </Visibility>
            </Segment>
          </Grid.Column>
          <Grid.Column width={10}>
            <Segment
              basic
              style={{
                height: "90vh",
                margin: 0,
                padding: 0,
              }}
            >
              <Transition
                animation={"fade left"}
                visible={visible}
                duration={900}
              >
                <Image
                  src={require(`assets/${t('christmas-table.1')}`)}
                  style={{
                    width: "70%",
                    margin: "10% 0 0 10%",
                  }}
                  alt="christmas-menu"
                  as={Link}
                  to="/christmasmenu"
                />
              </Transition>
            </Segment>
          </Grid.Column>
        </Grid>
      </Responsive>

      <Responsive maxWidth={750}>
        <Grid
          style={{ marginTop: "0%", backgroundColor: "rgb(35,31,32)" }}
          centered
        >
          <Image
            src={require(`assets/${t('christmas-table.2')}`)}
            style={{
              width: "80%",
              padding: 0,
              margin: "5% 0 0 5%",
            }}
            alt="christmas-menu"
            as={Link}
            to="/christmasmenu"
          />
        </Grid>
      </Responsive>
    </>
  );
};

export default ChristmasTable;
