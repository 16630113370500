import logo from "assets/logo.svg";
import { useRouter, SearchContext } from "context/index";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Grid,
  Icon,
  Image,
  Input,
  Menu,
  Segment,
  Sidebar,
  Header,
  Button,
  Flag
} from "semantic-ui-react";
import beef from "assets/beef.svg";
import chicken from "assets/chicken.svg";
import turkey from "assets/turkey.svg";
import pork from "assets/pig.svg";
import FooterComponent from "components/landing/FooterComponent";
import recipeBanner from "assets/recipebanner.svg";
import { useTranslation } from 'react-i18next';

const SidebarComponent = ({ children }) => {
  const location = useRouter().location.pathname;
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  console.log(search);

  const { t, i18n } = useTranslation();

  function handleClick(lang){
    i18n.changeLanguage(lang)
  }

  return (
    <>
      <SearchContext.Provider value={{ search, setSearch }}>
        {location === "/recipes" && (
          <Menu fixed="top" style={{ height: "6vh" }}>
            <Menu.Menu position="right">
              <Menu.Item style={{ width: "20vw", marginRight: "5vw" }}>
                <Input
                  value={search}
                  icon="search"
                  // fluid
                  // iconPosition="left"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={"Αναζήτηση"}
                  size="large"
                />
              </Menu.Item>
              {/* <Grid centered> */}

              <Menu.Item>
                <Header as="h1" icon textAlign="center">
                  <Image alt="pork"src={pork} style={{ maxHeight: "3vh" }} />
                </Header>
              </Menu.Item>
              <Menu.Item>
                <Header as="h1" icon textAlign="center">
                  <Image src={turkey} style={{ maxHeight: "3vh" }} alt="turkey"/>
                </Header>
              </Menu.Item>
              <Menu.Item>
                <Header as="h1" icon textAlign="center">
                  <Image src={chicken} style={{ maxHeight: "3vh" }} alt="chicken" />
                </Header>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Item>
              <Header as="h1" icon textAlign="center">
                <Image src={beef} alt="beef" />
              </Header>
            </Menu.Item>
          </Menu>
        )}

        <Sidebar.Pushable as={Segment}>
         
          <Button
            // basic
            icon={
              <Icon
                size={"large"}
                // style={{ color: location === '/recipes' ? '#fff' : '#2c2015' }}
                style={{ color: "#fff" }}
                name="sidebar"
              />
            }
            size={"massive"}
            circular
            style={{
              position: "fixed",
              left: "1vw",
              top: location === "/recipes" ? "5vw" : "1vw",
              boxShadow: "none",
              backgroundColor: "transparent",
              zIndex: 10,
            }}
            aria-label="sidebar"
            onClick={() => {
              setVisible(!visible);
            }}
          />
          <Sidebar
            as={Menu}
            animation="push"
            icon="labeled"
            // inverted
            vertical
            width="thin"
            direction="left"
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <Flag name="gr" onClick={()=>handleClick('el')}/>
            <Flag name="gb" onClick={()=>handleClick('en')}/>
            {/* <Segment circular size="massive" /> */}
            <Grid container centered padded>
              <Grid.Row centered>
                <Grid.Column
                  style={{
                    alignContent: "center",
                    marginTop: "10vh",
                  }}
                  width={location === "/recipes" ? 16 : 10}
                  textAlign="center"
                >
                  <Image
                    size="medium"
                    style={{
                      transform:
                        location === "/recipes" ? "rotate(-30deg)" : "none",
                    }}
                    src={location === "/recipes" ? recipeBanner : logo}
                    alt="logo"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider hidden style={{ marginBottom: "5vh" }} />
            <Menu.Item
              as={Link}
              active={location === "/" || location === "/home"}
              to="/home"
            >
              <Icon size="large" style={{ color: "#9d6946" }} name="home" />
              <pre style={{ fontFamily: "Roboto", fontWeight: 500 }}>
                {t("sidebar.1")}
              </pre>
            </Menu.Item>
            {/* <Menu.Item as={Link} to="/recipes" active={location === '/recipes'}>
              <Icon size="large" style={{ color: '#9d6946' }} name="book" />
              <pre style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                Συνταγές
              </pre>
            </Menu.Item> */}
            {/* <Menu.Item disabled>
              <Icon
                size="large"
                style={{ color: '#9d6946' }}
                name="shopping basket"
              />
              <pre style={{ fontFamily: 'Roboto', fontWeight: 500 }}>Αγορά</pre>
            </Menu.Item> */}
            <Menu.Item as={Link} to={"/menu"}>
              <Icon size="large" style={{ color: "#9d6946" }} name="map" />
              <pre style={{ fontFamily: "Roboto", fontWeight: 500 }}>{t("sidebar.2")}</pre>
            </Menu.Item>
            <Menu.Item as={Link} to={"/christmasmenu"}>
              <Icon
                size="large"
                style={{ color: "#9d6946" }}
                name="birthday cake"
              />
              <pre style={{ fontFamily: "Roboto", fontWeight: 500 }}>
              {t("sidebar.3")}
              </pre>
            </Menu.Item>  
          </Sidebar>

          <Sidebar.Pusher>
            {children} <FooterComponent></FooterComponent>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </SearchContext.Provider>
    </>
  );
};

export default SidebarComponent;
