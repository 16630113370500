import React from "react";
import {
  Segment,
  //Grid,
  Image,
  Label,
  Responsive,
  // Header,
  //Button,
  // Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import banner from "assets/banner.svg";
import header from "assets/header.svg";
import covidadvice from "assets/SVG/covidadvice.svg";
import { useTranslation } from 'react-i18next';

const HeaderComponent = () => {

  const { t } = useTranslation();
  
  return (
    <>
      <Responsive minWidth={750}>
      <Image
          style={{
            position: "absolute",
            zIndex: 1000,
            right: "0%",
            top: "25vh",
            width:"182px",
            height:"auto"
          }}
          as={"a"}
          href={require(`assets/${t('espa.2')}`)}
          target= "_blank"
          src={require(`assets/${t('espa.1')}`)}
          alt="espa"
        ></Image>
        <Image
          style={{
            position: "absolute",
            zIndex: 1000,
            right: "0%",
            top: "5vh",
          }}
          size="medium"
          as={"a"}
          href={"https://eody.gov.gr/neos-koronaios-covid-19/"}
          src={covidadvice}
          alt="covid"
        ></Image>

        <Segment
          basic
          style={{
            width: "25vw",
            height: "25vw",
            position: "absolute",
            zIndex: 10,
            top: "12vh",
            left: "10vw",
            color: "#ffffff",
          }}
          circular
        >
          <Image ui style={{ marginTop: "40%" }} src={banner} alt="banner"/>
          <Label
            as={Link}
            to={"/menu"}
            size={"large"}
            color="brown"
            ribbon="right"
            style={{ marginTop: "2vh", marginLeft: "-15vw" }}
          >
            {t('kreata.1')}
          </Label>
        </Segment>

        <Image
          style={{ margin: 0, padding: 0, borderBottom: "2vh solid #2e2014" }}
          src={header}
          alt="header-image"
        />
        {/* <Segment style={{ width: "100%", height: "50vh" }}></Segment> */}
      </Responsive>
      <Responsive maxWidth={750}>
        <Image
          ui
          size="medium"
          style={{ margin: "35% 0 0 3%", position: "absolute", zIndex: 100 }}
          src={banner}
          alt="banner"
        />

        <Image
          style={{ margin: 0, padding: 0, borderBottom: "2vh solid #2e2014" }}
          src={header}
          alt="header"
        />
      </Responsive>
    </>
  );
};

export default HeaderComponent;
