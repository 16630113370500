import React from "react";
import {
  Segment,
  Grid,
  //Transition,
  Image,
  // Button,
  Icon,
  // Header,
  Responsive,
} from "semantic-ui-react";
import meatLogos from "assets/SVG/meatlogos.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const RecipesComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Responsive minWidth={750}>
        <Grid
          style={{
            width: "100vw",
            height: "100vh",
            borderTop: "2vh solid #2e2014",
          }}
        >
          <Grid.Column width={8} style={{ padding: 0 }}>
            <Segment
              basic
              style={{
                height: "100%",
                width: "100vw",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/cardboard-flat.png")`,
                backgroundColor: "#a87740",
                backgroundRepeat: "repeat",
                backgroundSize: "10%",
                position: "absolute",
                clipPath: `polygon(0 0, 65% 0, 35% 100%, 0 100%)`, //polygon(100% 0, 30% 100%, 100% 100%)clip-path: polygon(0 0, 100% 0, 55% 100%, 0 100%);
                backgroundAttachment: "fixed",
                opacity: "0.2",
                zIndex: "-2",
              }}
            ></Segment>
            <Segment
              basic
              style={{
                margin: "0",
                padding: "0",
                height: "100%",
                width: "100vw",
                backgroundImage: `url(${meatLogos})`,
                backgroundColor: "#a87740",
                backgroundRepeat: "repeat",
                backgroundSize: "25%",
                position: "absolute",
                clipPath: `polygon(65% 0, 100% 0, 100% 100%, 45% 100%)`,

                backgroundAttachment: "fixed",
                zIndex: "-1",
                opacity: "0.6",
              }}
            ></Segment>
            <Segment
              basic
              style={{
                margin: "0",
                padding: "0",
                height: "100%",
                width: "100vw",

                backgroundImage: `linear-gradient(to right,  #ad1f1f, #a73737)`,
                backgroundRepeat: "fixed",

                position: "absolute",
                clipPath: `polygon(63% 0, 65% 0, 45% 100%, 35% 100%)`,

                backgroundAttachment: "fixed",
                zIndex: "-1",
                opacity: "1",
              }}
            ></Segment>
            <Image
              style={{
                width: "55%",
                height: "35%",
                margin: "10% 0 0 15%",
              }}
              as={Link}
              to="/menu"
              src={require(`assets/${t('recipescomponent.2')}`)}
              alt="athaniasia-suggests"
            ></Image>

            <Image
              src={require(`assets/SVG/${t('recipescomponent.1')}`)}
              style={{
                width: "40%",
                height: "22%",
                margin: "5% 0 0 15%",
                borderTop: "2vh dashed brown",
                paddingTop: "2%",
                // fontFamily: 'Verveine-Regular',
                // fontWeight: '600',
                // fontStyle: 'normal'
              }}
              alt="athanasia-suggests"
              as={Link}
              to="/menu"
            ></Image>
          </Grid.Column>
          <Grid.Column width={8} verticalAlign="middle">
            <Link to="/menu" aria-label="menu">
              <Icon
                // onClick={() => {}}
                circular
                name="book"
                size="massive"
                color="red"
                style={{
                  backgroundColor: "#f0ebe6",
                  textDecorationColor: "rgba(164,44,44,1)",
                  boxShadow: `0 0 0 0.1em rgba(169,44,44,1) inset`,
                  marginLeft: "35%",
                }}
              ></Icon>
            </Link>
          </Grid.Column>
        </Grid>
      </Responsive>
      <Responsive maxWidth={750}>
        <Grid
          style={{
            width: "100vw",
            height: "70vh",
            borderTop: "2vh solid #2e2014",
          }}
        >
          <Grid.Column width={8} style={{ padding: 0 }}>
            <Segment
              basic
              style={{
                height: "100%",
                width: "100vw",
                backgroundImage: `url("https://www.transparenttextures.com/patterns/cardboard-flat.png")`,
                backgroundColor: "#a87740",
                backgroundRepeat: "repeat",
                backgroundSize: "10%",
                position: "absolute",
                clipPath: `polygon(0 77%, 100% 59%, 100% 0, 0 0)`, //polygon(100% 0, 30% 100%, 100% 100%)clip-path: polygon(0 0, 100% 0, 55% 100%, 0 100%);
                backgroundAttachment: "fixed",
                opacity: "0.2",
                zIndex: "-2",
              }}
            ></Segment>
            <Segment
              basic
              style={{
                margin: "0",
                padding: "0",
                height: "100%",
                width: "100vw",
                backgroundImage: `url(${meatLogos})`,
                backgroundColor: "#a87740",
                backgroundRepeat: "repeat",
                backgroundSize: "25%",
                position: "absolute",
                clipPath: `polygon(0 77%, 100% 59%, 100% 100%, 0 100%)`,

                backgroundAttachment: "fixed",
                zIndex: "-1",
                opacity: "0.6",
              }}
            ></Segment>
            <Segment
              basic
              style={{
                margin: "0",
                padding: "0",
                height: "100%",
                width: "100vw",

                backgroundImage: `linear-gradient(to right,  #ad1f1f, #a73737)`,
                backgroundRepeat: "fixed",

                position: "absolute",
                clipPath: `polygon(0 77%, 100% 59%, 100% 57%, 0 69%)`,

                backgroundAttachment: "fixed",
                zIndex: "-1",
                opacity: "1",
              }}
            ></Segment>
            <Image
              style={{
                width: "100%",
                margin: "10% 0 0 15%",
              }}
              as={Link}
              to="/menu"
              src={require(`assets/${t('recipescomponent.2')}`)}
              alt="athanasia-suggests"
            ></Image>

            <Image
              src={require(`assets/SVG/${t('recipescomponent.1')}`)}
              style={{
                width: "100%",
                height: "22%",
                margin: "10% 0 0 15%",
                borderTop: "0.3vh dashed brown",
                paddingTop: "2%",

                // fontFamily: 'Verveine-Regular',
                // fontWeight: '600',
                // fontStyle: 'normal'
              }}
              alt="athanasia-suggests"
              as={Link}
              to="/menu"
            ></Image>
          </Grid.Column>
          <Grid.Column width={8} verticalAlign="middle">
            <Link to="/menu">
              <Icon
                // onClick={() => {}}
                circular
                name="book"
                size="huge"
                color="red"
                style={{
                  backgroundColor: "#f0ebe6",
                  textDecorationColor: "rgba(164,44,44,1)",
                  boxShadow: `0 0 0 0.1em rgba(169,44,44,1) inset`,
                  marginLeft: "28%",
                  marginTop: "60%",
                }}
              ></Icon>
            </Link>
          </Grid.Column>
        </Grid>
      </Responsive>
      {/* <Grid
        celled
        style={{
          width: '100vw',
          height: '100vh',
          borderTop: '2vh solid #2e2014'
        }}
      ></Grid> */}
    </>
  );
};

export default RecipesComponent;
